<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<template>
  <div class="vipCardManagement">
    <div class="search-container search-container-new">
      <el-row >
        <el-col :span="20">
          <el-form ref="form" :model="form" label-width="100px">
            <el-row>
              <el-col :span="6">
                <el-form-item prop="merchantId" label="发行商户">
                  <!-- <el-select v-model="form.merchantId" style="width: 100%">
                    <el-option
                      v-for="item in merchantBox"
                      :key="item.merchantId"
                      :label="item.merchantName"
                      :value="item.merchantId"
                    ></el-option>
                  </el-select> -->
                  <el-input v-model="merchantName" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="name" label="销售活动名称">
                  <el-input clearable v-model="form.name" placeholder="请输入销售活动名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="type" label="产品类型">
                  <el-select v-model="form.type" style="width: 100%">
                    <el-option label="会员卡" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="state	" label="状态">
                  <el-select v-model="form.state" style="width: 100%">
                    <el-option label="未发布" :value="1"></el-option>
                    <el-option label="已发布" :value="2"></el-option>
                    <el-option label="活动中" :value="3"></el-option>
                    <el-option label="已结束" :value="4"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item prop="time" label="活动时间">
                  <el-date-picker
                    v-model="form.time"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="4" style="text-align:right">
          <el-button @click="cleanForm" class="clean-button">{{$t('reset')}}</el-button>
          <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="contant">
      <noData v-if="tableData.length === 0" />
      <div v-else :style="{height: (tableHeight - 10) + 'px'}" class="card-contant">
        <div v-for="(item, index) in tableData" :key="index">
          <!-- 活动中 -->
          <div class="c-item type1" v-if="item.state === 3">
            <img src="@/assets/images/product-icon1.png" class="c-icon" alt="" width="60px" srcset="">
            <div class="df s-info">
              <img src="@/assets/images/product-img1.png" class="s-img" alt="" srcset="">
              <div class="f-cell">
                <div class="sales-title elps">{{item.name}}</div>
                <div class="sales-type elps2">产品类型: {{item.type === 1 ? '会员卡' : ''}}</div>
                <div class="sales-time elps">开始时间: {{item.startTime | formateDate}}</div>
                <div class="sales-time elps">结束时间: {{item.endTime | formateDate}}</div>
              </div>
            </div>
            <div class="c-btn-div">
              <div class="btn-list df ai">
                <div class='f-cell'></div>
                <div class="btn" @click="openEditDrawer(item.id, '3')">
                  <img src="@/assets/images/hd-icon1.png" alt="" srcset="">
                  详情
                </div>
                <div class="btn" @click="openConfirmDialog(item.id, 2)" v-if="buttonAuth.includes('memberCard:activity:stop')">
                  <img src="@/assets/images/hd-icon6.png" alt="" srcset="">
                  结束活动
                </div>
                <div class="btn" @click="openResultDialog(item.id)" v-if="buttonAuth.includes('memberCard:activity:performanceData')">
                  <img src="@/assets/images/hd-icon5.png" alt="" srcset="">
                  效果数据
                </div>
                <div class='f-cell'></div>
              </div>
            </div>
          </div>
          <!-- 未发布 -->
          <div class="c-item type2" v-if="item.state === 1">
            <img src="@/assets/images/product-icon2.png" class="c-icon" alt="" width="60px" srcset="">
            <div class="df s-info">
              <img src="@/assets/images/product-img2.png" class="s-img" alt="" srcset="">
              <div class="f-cell">
                <div class="sales-title elps">{{item.name}}</div>
                <div class="sales-type elps2">产品类型: {{item.type === 1 ? '会员卡' : ''}}</div>
                <div class="sales-time elps">开始时间: {{item.startTime | formateDate}}</div>
                <div class="sales-time elps">结束时间: {{item.endTime | formateDate}}</div>
              </div>
            </div>
            <div class="c-btn-div">
              <div class="btn-list df ai">
                <div class='f-cell'></div>
                <div class="btn" @click="openEditDrawer(item.id, '3')">
                  <img src="@/assets/images/hd-icon1.png" alt="" srcset="">
                  详情
                </div>
                <div class="btn" @click="openConfirmDialog(item.id, 1)" v-if="buttonAuth.includes('memberCard:activity:delete')">
                  <img src="@/assets/images/hd-icon2.png" alt="" srcset="">
                  删除
                </div>
                <div class="btn" @click="openConfirmDialog(item.id, 9)" v-if="buttonAuth.includes('memberCard:activity:publish')">
                  <img src="@/assets/images/hd-icon3.png" alt="" srcset="">
                  发布
                </div>
                <div class="btn" @click="openEditDrawer(item.id, '2')" v-if="buttonAuth.includes('memberCard:activity:update')">
                  <img src="@/assets/images/hd-icon4.png" alt="" srcset="">
                  编辑
                </div>
                <div class='f-cell'></div>
              </div>
            </div>
          </div>
          <!-- 已发布 -->
          <div class="c-item type3" v-if="item.state === 2">
            <img src="@/assets/images/product-icon3.png" class="c-icon" alt="" width="60px" srcset="">
            <div class="df s-info">
              <img src="@/assets/images/product-img3.png" class="s-img" alt="" srcset="">
              <div class="f-cell">
                <div class="sales-title elps">{{item.name}}</div>
                <div class="sales-type elps2">产品类型: {{item.type === 1 ? '会员卡' : ''}}</div>
                <div class="sales-time elps">开始时间: {{item.startTime | formateDate}}</div>
                <div class="sales-time elps">结束时间: {{item.endTime | formateDate}}</div>
              </div>
            </div>
            <div class="c-btn-div">
              <div class="btn-list df ai">
                <div class='f-cell'></div>
                <div class="btn" @click="openEditDrawer(item.id, '3')">
                  <img src="@/assets/images/hd-icon1.png" alt="" srcset="">
                  详情
                </div>
                <div class="btn" @click="openResultDialog(item.id)" v-if="buttonAuth.includes('memberCard:activity:performanceData')">
                  <img src="@/assets/images/hd-icon5.png" alt="" srcset="">
                  效果数据
                </div>
                <div class='f-cell'></div>
              </div>
            </div>
          </div>
          <!-- 已结束 -->
          <div class="c-item type4" v-if="item.state === 4">
            <img src="@/assets/images/product-icon4.png" class="c-icon" alt="" width="60px" srcset="">
            <div class="df s-info">
              <img src="@/assets/images/product-img4.png" class="s-img" alt="" srcset="">
              <div class="f-cell">
                <div class="sales-title elps">{{item.name}}</div>
                <div class="sales-type elps2">产品类型: {{item.type === 1 ? '会员卡' : ''}}</div>
                <div class="sales-time elps">开始时间: {{item.startTime | formateDate}}</div>
                <div class="sales-time elps">结束时间: {{item.endTime | formateDate}}</div>
              </div>
            </div>
            <div class="c-btn-div">
              <div class="btn-list df ai">
                <div class='f-cell'></div>
                <div class="btn" @click="openEditDrawer(item.id, '3')">
                  <img src="@/assets/images/hd-icon1.png" alt="" srcset="">
                  详情
                </div>
                <!-- <div class="btn" @click="openConfirmDialog(item.id, 2)">
                  <img src="@/assets/images/hd-icon6.png" alt="" srcset="">
                  结束活动
                </div> -->
                <div class="btn" @click="openResultDialog(item.id)" v-if="buttonAuth.includes('memberCard:activity:performanceData')">
                  <img src="@/assets/images/hd-icon5.png" alt="" srcset="">
                  效果数据
                </div>
                <div class='f-cell'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-content">
        <div>
          <el-button class="default-button" @click="openaddDrawer(true)" v-if="buttonAuth.includes('memberCard:activity:add')">创建销售活动</el-button>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[12, 24, 36, 48]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="form.total">
        </el-pagination>
      </div>
    </div>
    <result-data-dialog
      ref="resultDataDialog"
      :Language="Language"
    ></result-data-dialog>
    <!--新增编辑会员卡抽屉弹窗 -->
    <add-drawer
      ref="addDrawer"
      :Language="Language"
      :isEdit="isEdit"
      @closeDialog="changeAddDialog"
    ></add-drawer>
    <!-- 确认弹窗 -->
    <confirm-dialog
    ref="confirmDialog"
    :text="confirmDialog.text"
    :show="confirmDialog.visible"
    @sureDialog="sureConfirmDialog"
    @closeDialog="changeConfirmDialog"
    ></confirm-dialog>
  </div>
</template>
<script>
import mixin from "@/mixins/index";
import apis from "@/apis";
import { mapState } from "vuex";
import confirmDialog from "@/components/confirmDialogNew.vue";
import IndexClass from "./indexClass";
import noData from "@/components/noData.vue";
import resultDataDialog from "./components/resultDataDialog.vue";
import addDrawer from "./components/addDrawer.vue";
import moment from 'moment'

export default {
  mixins: [mixin],
  components: {
    confirmDialog,
    noData,
    // detailDialog,
    addDrawer,
    resultDataDialog,
  },
  filters: {
    formateDate: (value, type = 'YYYY-MM-DD') => {
      if (!value) return ''
      value = value.toString()
      return moment(value).format(type)
    },
  },
  data() {
    return {
      sportsProjectList: [],
      venueName: "",
      form: new IndexClass("form"), // 实例化一个表单的变量
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        text: '',
        data: {},
      },
      sportNewList: [],
      merchantBox: [],
      isEdit: '',
      merchantName: '',
    };
  },
  mounted() {},
  activated() {
    this.$http.get(apis.findAllMerchant).then((res) => {
      if (res.data.code === 0) {
        this.merchantBox = res.data.data
        this.merchantBox.forEach((item) => {
          if (item.merchantId === this.merchantAccountDetailInfoDTO.merchantId) {
            this.merchantName = item.merchantName
          }
        });
      }
    })
  },
  computed: {
    ...mapState(["Language", "DROPDOWNBOX", "TOKEN", "venueId", "merchantAccountDetailInfoDTO"]),
  },
  methods: {
    // 打开效果数据
    openResultDialog(id) {
      this.$refs.resultDataDialog.getDetails(id)
    },
    // 打开购卡抽屉
    openaddDrawer() {
      this.isEdit = '1'
      this.$refs.addDrawer.showDrawer()
    },
    // 编辑
    openEditDrawer(id, type) {
      this.isEdit = type
      this.$refs.addDrawer.getDetails(id)
    },
    // 删除
    modelDelete() {
      const formData = {
        id: this.confirmDialog.data.id,
      };
      this.$http
        .post(apis.activitydelete, formData)
        .then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog();
            this.$message.success("删除成功!");
            // this.searchEvent();
          }
        });
    },
    // 发布
    public() {
      const formData = {
        id: this.confirmDialog.data.id,
      };
      this.$http
        .post(apis.activitypublish, formData)
        .then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog();
            this.$message.success("发布成功!");
            this.searchEvent();
          }
        });
    },
    // 结束
    over() {
      const formData = {
        id: this.confirmDialog.data.id,
      };
      this.$http
        .post(apis.activitystop, formData)
        .then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog();
            this.$message.success("活动结束!");
            this.searchEvent();
          }
        });
    },
    // 清空按钮
    cleanForm() {
      this.$refs.form.resetFields();
      this.form = new IndexClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    searchEvent() {
      this.form = {
        ...this.form,
        ...{
          pageNum: 1,
          pageSize: 12,
          total: 0,
        },
      }
      console.log('点击了搜索按钮')
      // 这里执行搜索操作
      this.searchData()
    },
    // 查询
    searchData() {
      const formData = { ...this.form }
      if (formData.time && formData.time.length > 0) {
        formData.startTime = formData.time[0]
        formData.endTime = formData.time[1]
      }
      formData.pageNum = this.form.pageNum
      formData.pageSize = this.form.pageSize
      this.$http
        .post(apis.activitygetList, formData)
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.rows;
            this.form.total = res.data.total;
          }
        });
    },
    // 修改数据回显
    changeAddDialog(dialogStatus, isEdit = false, data = {}) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.addDialog.data = data;
          this.$refs["addDialog"].getDataQuery(data);
        }
      } else {
        this.searchEvent();
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.id = "";
        this.searchEvent();
      }
    },
    /**
     * 删除和发布点击确认
     * id: 对应的id值
     * type 1：删除 9:发布
     */
    /* eslint-disable */
    // 结束活动确认框
    openConfirmDialog(id, type) {
      this.confirmDialog.type = type;
      let tipText = ''
      switch (type) {
        case 1:
          tipText = '确定删除该销售活动吗?'
          break
        case 2:
          tipText = '确认提前结束该销售活动吗？'
          break
        case 9:
          tipText = '确认发布该销售活动吗?'
          break
        default:
          break
      }
      this.confirmDialog.text = tipText
      this.confirmDialog.data.id = id;
      this.changeConfirmDialog(true, type);
    },
    openConfirmDialogNew(id, type) {
      if (type === 2) {
        this.$message.success("敬请期待!");
      }
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      if (this.confirmDialog.type === 1) { // 删除
        this.modelDelete();
      } else if (this.confirmDialog.type === 9) { // 发布
        this.public()
      } else if (this.confirmDialog.type === 2) { // 结束
        this.over()
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.scoped.less";
</style>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.less";
.vipCardManagement {
  .page-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  .contant {
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E4E9F1;
    margin: 0 15px;
    padding: 20px;
    .card-contant {
      overflow-y: auto;
    }
    .c-item{ background: #f5f6f7; border-radius: 4px; position: relative; overflow: hidden; width: 330px;
      height: 148px; padding: 18px;  box-sizing: border-box; float: left; margin: 0 20px 20px; box-shadow: 2px 3px 8px 2px #dddddd;
      .c-icon{ position: absolute; top: 0; right: 0; z-index: 2;}
    }
    .s-info{ 
        .s-img{ margin-right: 11px;width: 40px; height: 40px; display: block;}
        .sales-title{ width: 200px; line-height: 40px; font-size: 16px; font-weight:bold; color: #ffffff;}
        .sales-type{ width: 230px; line-height: 26px; height: 30px; font-size: 14px; color: #ffffff;}
        .sales-time{ width: 230px; line-height: 26px; font-size: 14px; color: #ffffff;}
    }
    .c-btn-div{ position: absolute; left: 0; top: 0; z-index: 2;width: 330px; height: 148px;
    background: rgba(0,0,0,0.7); display: none;}
    .c-item:hover .c-btn-div{ display: block; }
    .h-line{position: absolute; left: 0; z-index: 3;width: 330px; height: 1px; background: #eeeeee;}
    .s-line{position: absolute; top: 0; z-index: 3;width: 1px; height: 148px; background: #eeeeee;}
    .btn-list{width: 330px; height: 148px;
        // .btn{width: 165px; height: 74px; text-align: center; line-height: 74px; cursor: pointer; color: #333333;
        // }
        // .btn:hover{ font-weight: bold;}
      .btn{ height: 67px; text-align: center; cursor: pointer; color: #ffffff; margin: 0 10px;
        img{ width: 41px; height: 41px; display: block; margin:0 auto 6px;}
      }
    }
    .c-item.type1{background: url('../../../assets/images/product-bg1.png') center center no-repeat;
        background-size: 100%;}
    .c-item.type2{background: url('../../../assets/images/product-bg2.png') center center no-repeat;
        background-size: 100%;}
    .c-item.type3{background: url('../../../assets/images/product-bg3.png') center center no-repeat;
        background-size: 100%;}
    .c-item.type4{background: url('../../../assets/images/product-bg4.png') center center no-repeat;
        background-size: 100%;}
  }
}
</style>

<template>
    <el-drawer
        size="85%"
        :append-to-body="true"
        :visible.sync="showFlag"
        custom-class="drawer-100"
        :wrapperClosable="false"
        :destroy-on-close="true"
    >
        <div slot="title" class="d-title">
            <el-page-header @back="goBack" :content="isEdit === '1' ? '新增产品销售活动' : isEdit === '2' ? '编辑产品销售活动' : '查看产品销售活动'"></el-page-header>
        </div>
        <el-container class="d-body">
            <el-main>
                <div class="m-div">
                    <el-steps :active="activeStep" simple>
                        <el-step class="step-item" :icon="activeStep >= 1 ? 'el-icon-circle-check' : ''" :title="isEdit === '1' ? '1 创建活动' : isEdit === '2' ? '1 编辑活动' : '1 查看活动'" >
                            <div slot="title" @click="setStep(1)">{{isEdit === '1' ? '1 创建活动' : isEdit === '2' ? '1 编辑活动' : '1 查看活动'}}</div>
                        </el-step>
                        <el-step class="step-item" :icon="activeStep >= 2 ? 'el-icon-circle-check' : ''" title="2 设置活动产品销售方案" >
                            <div slot="title" @click="setStep(2)">2 设置活动产品销售方案</div>
                        </el-step>
                        <el-step class="step-item" :icon="activeStep >= 3 ? 'el-icon-circle-check' : ''" title="3 完成并发布" >
                            <div slot="title" @click="setStep(3)">3 完成并发布</div>
                        </el-step>
                    </el-steps>
                    <el-row class="margin-top-40">
                        <div class="df">
                            <div class="f-cell"></div>
                            <div>
                                <div v-show="activeStep === 1" style="width: 500px">
                                    <el-form ref="form" :model="form" class="form" label-width="120px" :rules="rules">
                                        <el-form-item label="活动名称" prop="name">
                                            <el-input v-model="form.name" class="w-full" :disabled="isEdit==='3'" :maxlength="20" placeholder="请输入活动名称(12字符以内)"></el-input>
                                        </el-form-item>
                                        <el-form-item label="活动时间范围" prop="time">
                                            <el-date-picker
                                            v-model="form.time"
                                            :disabled="isEdit==='3'"
                                            class="w-full"
                                            clearable
                                            style="width: 100%"
                                            type="daterange"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            @change="changeTime"
                                            >
                                            </el-date-picker>
                                        </el-form-item>
                                        <el-form-item label="参与产品类型" prop="productType">
                                            <el-select v-model="form.productType" class="w-full" :disabled="isEdit==='3'" placeholder="请选择">
                                                <el-option label="会员卡" :value="1"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item v-if="form.time && form.time.length > 0" label="参与产品" prop="cardEntityIds">
                                            <el-cascader :disabled="isEdit==='3'" :options="cardList" style="width: 100%" v-model="form.cardEntityIds" :props="props" clearable></el-cascader>
                                            <!-- <el-transfer
                                                filterable
                                                :filter-method="filterMethod"
                                                :titles="['未选', '已选']"
                                                filter-placeholder="请输入会员卡名称"
                                                v-model="form.cardEntityIds"
                                                :data="cardList">
                                            </el-transfer> -->
                                        </el-form-item>
                                    </el-form>
                                </div>
                                <div v-show="activeStep === 2" class="set-div" style="min-width:900px;">
                                    <div class="total df ai margin-bottom-20">
                                        <i class="el-icon-warning margin-right-30"></i>
                                        <div>已设置<span>{{numSet}}</span>项</div>
                                        <div>共计<span>{{total}}</span>项</div>
                                    </div>
                                    <el-table
                                        :data="tableData"
                                        tooltip-effect="dark"
                                        style="width: 100%">
                                        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                                        <el-table-column
                                        label="产品名称"
                                        show-overflow-tooltip
                                        align="center"
                                        header-align="center"
                                        prop="name"
                                        min-width="120">
                                        </el-table-column>
                                        <el-table-column
                                        prop="inventoryQty"
                                        label="发售数量"
                                        align="center"
                                        header-align="center"
                                        show-overflow-tooltip
                                        width="100">
                                        </el-table-column>
                                        <el-table-column
                                        prop="isPriceCut"
                                        label="是否降价"
                                        align="center"
                                        header-align="center"
                                        :formatter="formatter"
                                        show-overflow-tooltip
                                        width="100">
                                        </el-table-column>
                                        <el-table-column
                                        prop="giveMark"
                                        label="是否赠送"
                                        align="center"
                                        header-align="center"
                                        :formatter="formatter"
                                        show-overflow-tooltip
                                        width="100">
                                        </el-table-column>
                                        <el-table-column
                                        prop="isSet"
                                        label="状态"
                                        align="center"
                                        header-align="center"
                                        show-overflow-tooltip
                                        width="100">
                                            <template slot-scope="scope">
                                                <div class="setDiv">
                                                    <div :class="scope.row.isSet === 1 ? 'green' : 'gary'"></div>
                                                    <div>{{scope.row.isSet === 1 ? '已设置' : '未设置'}}</div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                        width="150px"
                                        align="center"
                                        header-align="center"
                                        :label="$t('option')">
                                            <template slot-scope="scope">
                                                <el-button type="text" v-if="isEdit!=='3' && scope.row.isSet !== 1" @click="schemeDialog(scope.row.id, '1')">设置</el-button>
                                                <el-button type="text" v-if="isEdit!=='3'" @click="schemeDialog(scope.row.id, '2')">编辑</el-button>
                                                <el-button type="text" @click="schemeDialog(scope.row.id, '3')">查看</el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                                <div class="success-div" v-show="activeStep === 3">
                                    <!-- <i class="el-icon-success s-icon"></i> -->
                                    <img src="@/assets/images/success-icon.png" class="s-icon" alt="" width="150px" srcset="">
                                    <div class="s-text">销售活动已{{isEdit === '1' ? '新增' : '修改'}}完成</div>
                                    <div class="s-btn" @click="closeDialog">去发布</div>
                                </div>
                            </div>
                            <div class="f-cell"></div>
                        </div>
                    </el-row>
                </div>
            </el-main>
            <el-footer v-if="activeStep !== 3">
                <el-button v-if="activeStep === 3" class="footer-confirm" @click="closeDialog">去发布</el-button>
                <el-button v-if="activeStep !== 1" class="footer-confirm" @click="goNext(-1)">上一步</el-button>
                <el-button v-if="activeStep !== 3" class="footer-confirm" @click="goNext(1)">下一步</el-button>
                <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
            </el-footer>
        </el-container>
        <!-- 数据弹窗 -->
        <scheme-dialog
        ref="schemeDialog"
        :editOrCheck="editOrCheck"
        @save="saveScheme"
        ></scheme-dialog>
    </el-drawer>
</template>
<script>
// import mixin from '@/mixins/index'
import apis from "@/apis";
import { mapState } from "vuex";
import schemeDialog from "./schemeDialog.vue";

export default {
    // mixins: [mixin],
    components: {
        schemeDialog,
    },
    props: {
        isEdit: {
            require: true,
            type: String,
        },
    },
    data() {
        return {
            showFlag: false,
            form: {
                cardEntityIds: [],
                time: [],
                endTime: '',
                startTime: '',
                productType: '',
                name: '',
            },
            rules: {
               productType: [{ required: true, message: '请选择产品类型', trigger: ['blur', 'change'] }],
               time: [{ required: true, message: '请选择时间范围', trigger: ['blur'] }],
               name: [{ required: true, message: '请输入活动名称', trigger: ['blur', 'change'] }],
               cardEntityIds: [{ required: true, message: '请选择参与产品', trigger: ['blur', 'change'] }],
            },
            apis,
            activeStep: 1,
            cardList: [],
            props: {
                multiple: true,
                value: 'id',
                label: 'name',
                emitPath: false,
            },
            // filterMethod(query, item) {
            //     return item.label.indexOf(query) > -1;
            // },
            tableData: [],
            id: '',
            total: 0,
            editOrCheck: '',
            numSet: '',
        };
    },
    computed: {
        ...mapState(['Language', 'DROPDOWNBOX', "TOKEN", "venueId", "venueList", 'merchantAccountDetailInfoDTO']),
    },
    mounted() {
    },
    methods: {
        init() {
            this.form = {
                cardEntityIds: [],
                time: [],
                endTime: '',
                startTime: '',
                productType: '',
                name: '',
            }
            // this.$refs.form.resetFields();
            if (this.$refs.form) {
                this.$refs.form.resetFields();
            }
            this.tableData = []
            this.id = ''
            this.activeStep = 1
            this.total = 0
            this.numSet = ''
        },
        // step切换点击
        setStep(index) {
            if (this.activeStep !== index) {
                this.activeStep = index
                if (this.activeStep === 2) {
                    this.getTableData()
                }
            }
        },
        // 弹框打开触发
        showDrawer(type = 1) {
            if (type === 1) {
                this.init()
            }
            this.activeStep = 1
            this.showFlag = true
        },
        // 日期选择改变
        changeTime(val) {
            console.log(val, 222)
            if (val) {
                const postObj = {
                    startTime: val[0],
                    endTime: val[1],
                    id: this.form.id,
                }
                this.getCardList(postObj)
            }
        },
        getCardList(params) {
            this.$http.get(apis.cardTypegetAllCardType, { params }).then((res) => {
                if (res.data.code === 0) {
                    this.cardList = res.data.data
                }
            })
        },
        // 效果数据
        schemeDialog(id, type) {
            this.editOrCheck = type
            this.$refs.schemeDialog.getDetail(id)
        },
        // 设置返回
        saveScheme() {
            this.getTableData()
        },
        goBack() {
            this.showFlag = false
        },
        // 下一步,上一步
        goNext(type) {
            if (type === -1) {
                // if (this.activeStep === 3) {
                //     this.activeStep = 1
                // } else {
                //     this.activeStep = this.activeStep - 1
                // }
                this.activeStep = this.activeStep - 1
            }
            if (type === 1) {
                if (this.activeStep === 1) {
                    this.$refs.form.validate((valid) => {
                        if (valid) {
                            if (this.isEdit === '1') { // 新增的逻辑
                                if (this.id) {
                                    this.activeStep = this.activeStep + 1
                                } else {
                                    const formData = { ...this.form }
                                    if (formData.time && formData.time.length > 0) {
                                        formData.startTime = formData.time[0]
                                        formData.endTime = formData.time[1]
                                    }
                                    formData.merchantId = this.merchantAccountDetailInfoDTO.merchantId
                                    this.$http.post(apis.activityadd, formData).then((res) => {
                                        if (res.data.code === 0) {
                                            this.id = res.data.data.id
                                            this.getTableData()
                                            this.activeStep = this.activeStep + 1
                                        }
                                    })
                                }
                            } else if (this.isEdit === '2') { // 修改的逻辑
                                const formData = { ...this.form }
                                if (formData.time && formData.time.length > 0) {
                                    formData.startTime = formData.time[0]
                                    formData.endTime = formData.time[1]
                                }
                                this.$http.post(apis.activityupdate, formData).then((res) => {
                                    if (res.data.code === 0) {
                                        this.getTableData()
                                        this.activeStep = this.activeStep + 1
                                    }
                                })
                            } else { // 查看的逻辑
                                this.activeStep = this.activeStep + 1
                                this.getTableData()
                            }
                        }
                    })
                } else if (this.activeStep === 2) {
                    let isPass = true
                    this.tableData.forEach((item) => {
                        if (item.isSet !== 1) {
                            isPass = false
                        }
                    })
                    if (isPass || this.isEdit === '3') {
                        this.activeStep = this.activeStep + 1
                    } else {
                        this.$message.error('请先设置所有数据')
                    }
                } else {
                    this.activeStep = this.activeStep + 1
                }
            }
        },
        // 获取表格数据
        getTableData() {
            this.$http.get(`${apis.productgetProductVoListById}?activityId=${this.id}`).then((res) => {
                if (res.data.code === 0) {
                    this.tableData = res.data.rows
                    this.total = res.data.total
                    let num = 0
                    this.tableData.forEach((item) => {
                        if (item.isSet === 1) {
                            num += 1
                        }
                    })
                    this.numSet = num
                }
            })
        },
        formatter(row, column, cellValue) {
            return cellValue === 1 ? '是' : '否';
        },
        getDetails(id) {
            this.$http.get(`${apis.activitygetById}?id=${id}`).then((res) => {
                if (res.data.code === 0) {
                    this.form = {
                        ...res.data.data,
                        time: [res.data.data.startTime, res.data.data.endTime],
                    }
                    // this.form
                    this.changeTime(this.form.time)
                    this.id = this.form.id
                    this.form.productType = this.form.type
                    this.showDrawer(2)
                }
            })
        },
        closeDialog() {
            this.init()
            this.$emit('closeDialog', false)
            this.showFlag = false
        },
    },
}
</script>
<style lang='scss' scoped>
.d-body{border-top: 1px solid #dddddd; height: 100%;}
.el-main{padding: 0;  border-bottom: 1px solid #dddddd;}
.el-footer{ text-align: center; line-height: 60px;}
.m-div{ padding: 20px 28px;}
.step-item{ cursor: pointer;}
.total{ height: 40px; border: 1px solid #7BB9FF; background: #EAF4FF;
    i{ color: #1890FF; font-size: 20px; margin: 0 13px 0 17px;}
    div{ margin-right: 17px; color: rgba(0, 0, 0, 0.65);
        span{color: #7BB9FF; padding: 0 5px;}
    }
}
.success-div{ text-align: center; padding-top: 150px;
    .s-icon{ display: block; margin: 0 auto; margin-bottom: 20px;}
    .s-text{ font-size: 20px; color: #333333; line-height: 29px; margin-bottom: 28px;font-weight: 500;}
    .s-btn{ width:100px; height:32px; line-height: 32px;; font-size: 14px; color: #ffffff;
    font-weight: 400; background: #3FCE9C; border-radius: 4px; margin: 0 auto; text-align: center;}
}
.setDiv {
    display: flex;
    align-items: center;
    .gary {
        width: 6px;
        height: 6px;
        background: #EAECF1;
        border-radius: 3px;
        margin-right: 10px;
    }
    .green {
        width: 6px;
        height: 6px;
        background: #439BFF;
        border-radius: 3px;
        margin-right: 10px;
    }
}
</style>
<style>
.drawer-100 .el-drawer__body{height: calc(100% - 80px);}
.set-div .el-table thead{color: rgba(0, 0, 0, 0.85);font-weight: 500;}
.set-div .el-table thead th{background: #FAFAFA;}
</style>
